import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div(({ fullWidth }) => [
  tw`mx-auto flex flex-wrap`,
  fullWidth ? '' : tw`container`,
]);

export const Title = tw.h2`w-full font-display text-2xl lg:text-4xl font-bold leading-tight text-center text-gray-800 mb-3 mt-8 md:mb-4 px-6`;

export const LineContainer = tw.div`w-full mb-4 md:mb-8`;

export const Line = tw.div`h-1 mx-auto w-64 opacity-25 my-0 py-0 rounded`;
