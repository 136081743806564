import tw from 'twin.macro';
import Section from '../components/Section/';
import { Badges } from '../components/Badges/';

export const LogoWrapper = tw.div`absolute w-full flex justify-center p-5 md:p-8 z-10`;

export const ImageWrapper = tw.div`flex w-full relative overflow-hidden`;

export const Header = tw.h1`font-display text-3xl lg:text-5xl font-bold leading-tight w-full text-center mb-8`;

export const Wrapper = tw.div`flex flex-wrap mx-auto max-w-screen-sm`;

export const IconWrapper = tw.div`flex items-center mb-8 w-full`;
export const Icon = tw.div`text-4xl mr-4	`;
export const Text = tw.div`flex-1 w-full	`;

export const Container = tw.section`w-full mx-auto text-center py-24 px-8 md:px-16 bg-primaryLight`;

export const Title = tw.h2`font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center`;

export const SubTitle = tw.h3`font-display mb-8 text-xl lg:text-3xl leading-tight`;

export const BadgesContainer = tw(
  Badges
)`absolute w-full bottom-0 flex flex-wrap justify-center items-center mt-4 pb-4`;

export const SectionContainer = tw(Section)`px-6 py-8 md:py-12`;
