import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import Section from '../components/Section/';
import { Badges } from '../components/Badges/';
import '../index.css';
import WideLogo from '../svgs/WideLogo';
import {
  LogoWrapper,
  ImageWrapper,
  Header,
  IconWrapper,
  Icon,
  Text,
  Container,
  Title,
  SubTitle,
  BadgesContainer,
  SectionContainer,
  Wrapper,
} from '../styles/droppoint-promo';

const IndexPage = ({ data: { Promo } }) => {
  return (
    <PageLayout hideNav>
      <SEO title="DropPoint werden" />
      <LogoWrapper>
        <WideLogo size={56} />
      </LogoWrapper>
      <ImageWrapper>
        <GatsbyImage
          image={Promo.childImageSharp.gatsbyImageData}
          className="w-full h-full"
          objectFit="cover"
          objectPosition="center center"
          loading="eager"
          alt=""
        />
        <BadgesContainer />
      </ImageWrapper>

      <SectionContainer>
        <Header>Werde ein DropPoint.</Header>
        <Wrapper>
          <IconWrapper>
            <Icon>
              <span role="img" aria-label="Paket">
                📦
              </span>
            </Icon>
            <Text>Empfange Sendungen für Deine Community. So einfach wie Freunde begrüßen.</Text>
          </IconWrapper>
          <IconWrapper>
            <Icon>
              <span role="img" aria-label="Geld ">
                💸
              </span>
            </Icon>
            <Text>
              Fair vergütet. Verdiene zwischen €0,50 und €1,00 pro Sendung. Erhalte zusätzliche
              Trinkgelder und Sondervergütungen.
            </Text>
          </IconWrapper>
          <IconWrapper>
            <Icon css={{ marginRight: '1.8rem !important' }}>
              <span role="img" aria-label="Handy">
                📱
              </span>
            </Icon>
            <Text>
              Bestimme selbst, wie viele Sendungen Du für Deine DropFriends vorhalten möchtest & wie
              groß/schwer die Pakete sein dürfen.
            </Text>
          </IconWrapper>
          <IconWrapper>
            <Icon>
              <span role="img" aria-label="Uhr">
                🕒
              </span>
            </Icon>
            <Text>
              Definiere Abholzeiten für Deine Community und erhalte positive Bewertungen und
              zusätzliche Buchungen.
            </Text>
          </IconWrapper>
        </Wrapper>
      </SectionContainer>

      <Container>
        <Title>Jetzt die DropFriends-App downloaden.</Title>

        <SubTitle>Erhältlich im App Store und im Google Play Store.</SubTitle>

        <Badges className="mt-4" />
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Promo: file(relativePath: { eq: "droppoint/promo.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default IndexPage;
