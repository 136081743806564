import React from 'react';
import { Line, LineContainer, Title, Wrapper } from './styles';

const Section = ({ children, title, titleId, className = '', fullWidth, ...rest }) => {
  return (
    <section className={`bg-white ${className}`} {...rest}>
      <Wrapper fullWidth={fullWidth}>
        {title ? (
          <>
            <Title id={titleId}>{title}</Title>
            <LineContainer>
              <Line className="gradient"></Line>
            </LineContainer>
          </>
        ) : null}
        {children}
      </Wrapper>
    </section>
  );
};

export default Section;
