import React from 'react';
import AppStoreBadge from '../../images/AppStoreBadge.svg';
import { AppleBadge, BadgeContainer } from './styles';

export const Badges = ({ className = '' }) => {
  return (
    <BadgeContainer className={className}>
      <AppleBadge
        title="Im App Store herunterladen"
        style={{
          display: 'inline-block',
          overflow: 'hidden',
          background: `url(${AppStoreBadge}) no-repeat`,
          width: 120,
          height: 40,
        }}
        href="https://apps.apple.com/de/app/dropfriends/id1491312504?mt=8"
      />
      <a href="https://play.google.com/store/apps/details?id=com.dropfriends.dropfriends">
        <img
          className="googleplay-badge"
          style={{ width: 133, margin: 0 }}
          alt="Jetzt bei Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
        />
      </a>
    </BadgeContainer>
  );
};
